<template>
    <el-form
        :rules="rules"
        :model="form"
        ref="form"
        :label-width="isChinese ? '180px' : '300px'"
        :validate-on-rule-change="false"
    >
        <el-form-item
            :label="$t('qi-ye-ming-cheng')"
            prop="chCompanyName"
            :rules="getRule($t('qi-ye-ming-cheng-bu-neng-wei-kong'))"
        >
            <el-input class="input2" v-model="form.chCompanyName"></el-input>
        </el-form-item>
        <el-form-item
            :label="$t('shi-fou-zai-zhong-guo-she-li-gong-si-ban-shi-chu')"
            prop="branch"
            :rules="getRule($t('qing-xuan-ze'), 'change')"
        >
            <el-radio-group v-model="form.branch">
                <el-radio :label="item.value" v-for="item in boolOptions" :key="item.value">{{
                    $t(item.label)
                }}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item
            :label="$t('qi-ye-ying-wen-ming')"
            prop="enCompanyName"
            :rules="getRule($t('qi-ye-ying-wen-ming-bu-neng-wei-kong'))"
        >
            <el-input class="input2" v-model="form.enCompanyName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('wang-zhan-zhu-ye')" prop="homepage">
            <el-input
                class="input3"
                :placeholder="$t('shu-ru-nin-de-gong-si-xian-shang-wang-zhan-di-zhi')"
                v-model="form.homepage"
            >
                <template slot="prepend">Http://</template>
            </el-input>
        </el-form-item>

        <el-form-item
            :label="$t('qi-ye-lei-bie')"
            prop="enterpriseType"
            :rules="getRule($t('qi-ye-lei-bie-bu-neng-wei-kong'), 'change')"
        >
            <el-checkbox-group v-model="form.enterpriseType">
                <el-checkbox
                    :label="item.value"
                    :key="index"
                    v-for="(item, index) in enterpriseTypes"
                    name="enterpriseType"
                    >{{ item.label }}</el-checkbox
                >
            </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('zhu-yao-chan-pin-fu-wu')" prop="productCategoryId">
            <div class="input3">
                <ProductCategory
                    v-model="form.productCategoryId"
                    :label.sync="form.customCategory"
                    :limit="6"
                    :backString="false"
                />
            </div>
        </el-form-item>

        <el-form-item :label="$t('ying-yong-ling-yu')" prop="applicationField">
            <div class="input3">
                <ApplicationField
                    class="input3"
                    :ids.sync="form.applicationField"
                    :label.sync="form.customApplicationField"
                    :backString="false"
                />
            </div>
        </el-form-item>
        <el-form-item :label="$t('qi-ye-logo')" prop="logo">
            <div class="logoList">
                <crop-upload v-model="form.logo" :width="500" :height="500" :ratio="[1, 1]" :imgWidth="160" name="logo">
                    <div slot="tips" class="tips">{{ $t('chi-cun') }}500x500px</div>
                </crop-upload>
                <crop-upload
                    v-model="form.logo1"
                    :width="280"
                    :height="120"
                    :ratio="[7, 3]"
                    :imgWidth="224"
                    key="22"
                    name="logo1"
                >
                    <div slot="tips" class="tips">{{ $t('chi-cun') }}280x120px</div>
                </crop-upload>
            </div>
        </el-form-item>

        <el-form-item :label="$t('tong-yi-she-hui-xin-yong-dai-ma')" v-if="form.branch" prop="uscc">
            <el-input
                class="input2"
                v-model="form.uscc"
                :maxlength="18"
                :placeholder="$t('qing-shu-ru-18-wei-tong-yi-she-hui-xin-yong-dai-ma')"
            ></el-input>
        </el-form-item>
        <el-form-item :label="$t('ying-ye-zhi-zhao-qi-xian')" v-if="form.branch" prop="businessLicensePeriod">
            <div class="form-right">
                <el-date-picker
                    class="input2"
                    v-model="form.businessLicensePeriod"
                    type="date"
                    :placeholder="$t('qing-xuan-ze-dao-qi-shi-jian')"
                    :disabled="form.noLimit"
                >
                </el-date-picker>
                <el-checkbox v-model="form.noLimit">{{ $t('wu-xian-qi') }}</el-checkbox>
            </div>
        </el-form-item>
        <el-form-item
            :label="$t('ying-ye-zhi-zhao')"
            v-if="form.branch"
            prop="businessLicenseImg"
            :rules="getRule($t('ying-ye-zhi-zhao-bu-neng-wei-kong'))"
        >
            <file-upload v-model="form.businessLicenseImg" single format="string" accept=".pdf,.jpg,.png" :size="2">
                <div slot="tips">
                    {{ $t('ge-shi-yao-qiu-jpgpngpdf-dan-wen-jian-da-xiao-bu-chao-guo-2m') }}
                </div>
            </file-upload>
            <!-- <single-upload
                v-model="form.businessLicenseImg"
                :width="300"
                :height="300"
                :ratio="[1, 1]"
                :imgWidth="160"
                name="businessLicenseImg"
            >
                <div slot="tips">
                    请上传清晰图片，格式jpg/png，文件大小不超过2M
                </div>
            </single-upload> -->
        </el-form-item>
        <el-form-item
            :label="$t('fa-ding-dai-biao-ren-zheng-jian')"
            prop="idimg"
            :rules="getRule($t('fa-ding-dai-biao-ren-zheng-jian-bu-neng-wei-kong'), 'change')"
        >
            <!-- <single-upload v-model="form.idimg" :width="300" :height="300" :ratio="[1, 1]" :imgWidth="160">
                <div slot="tips">
                    请上传法定代表人身份证/护照扫描件，格式jpg/png，单文件大小不超过2M
                </div>
            </single-upload> -->
            <file-upload v-model="form.idimg" single format="string" accept=".pdf,.jpg,.png" :size="2">
                <div slot="tips">
                    {{
                        $t(
                            'qing-shang-chuan-fa-ding-dai-biao-ren-shen-fen-zheng-hu-zhao-sao-miao-jian-ge-shi-yao-qiu-jpgpngpdf-dan-wen-jian-da-xiao-bu-chao-guo-2m'
                        )
                    }}
                </div>
            </file-upload>
        </el-form-item>

        <el-form-item
            :label="$t('qi-ta-zheng-ming-cai-liao')"
            prop="otherFiles"
            :rules="
                form.branch
                    ? {
                          required: false,
                          validator: (rule, value, callback) => {
                              callback();
                          },
                          trigger: 'change'
                      }
                    : getRule($t('qi-ta-zheng-ming-cai-liao-bu-neng-wei-kong'), 'change', true)
            "
        >
            <file-upload v-model="form.otherFiles" format="string" accept=".pdf,.jpg,.png">
                <div slot="tips">
                    {{
                        form.branch
                            ? $t(
                                  'shang-biao-zhu-ce-zheng-huo-xiang-guan-zheng-ming-cai-liao-ke-shang-chuan-duo-fen-cai-liao-ge-shi-yao-qiu-jpgpngpdf-dan-wen-jian-da-xiao-bu-chao-guo-2m-1'
                              )
                            : $t(
                                  'shang-biao-zhu-ce-zheng-huo-xiang-guan-zheng-ming-cai-liao-ke-shang-chuan-duo-fen-cai-liao-ge-shi-yao-qiu-jpgpngpdf-dan-wen-jian-da-xiao-bu-chao-guo-2m'
                              )
                    }}
                </div>
            </file-upload>
        </el-form-item>
        <el-form-item :label="$t('quan-qiu-zong-bu')" prop="address">
            <div class="input3">
                <Area
                    :country.sync="branchInformation.country"
                    :province.sync="branchInformation.province"
                    :city.sync="branchInformation.city"
                    :continent.sync="branchInformation.continent"
                />
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    style="margin-top: 14px"
                    :placeholder="$t('qing-shu-ru-xiang-xi-di-zhi')"
                    v-model="branchInformation.address"
                    resize="none"
                >
                </el-input>
            </div>
        </el-form-item>

        <el-form-item
            :label="$t('qi-ye-jian-jie')"
            prop="introduction"
            :rules="getRule($t('qi-ye-jian-jie-bu-neng-wei-kong'))"
        >
            <div class="input3">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 8 }"
                    :maxlength="1000"
                    show-word-limit
                    :placeholder="$t('qing-shu-ru-qi-ye-jian-jie')"
                    v-model="form.introduction"
                    resize="none"
                >
                </el-input>
            </div>
        </el-form-item>
        <el-form-item>
            <el-button type="warning" @click="onSubmit" :loading="loading">{{ $t('li-ji-chuang-jian') }}</el-button>
            <el-button @click="$router.go(-1)">{{ $t('qu-xiao') }}</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import { boolModels, EnterpriseType } from '../../utils/AppState';
import Cascader from '../../components/Cascader';
import { mapState } from 'vuex';
import formPage from '../../mixins/formPage';
import ProductCategory from '../../components/select/ProductCategory';
import ApplicationField from '../../components/select/ApplicationField';
import Area from '../../components/AreaInput';
import { format, parse } from 'date-fns';
export default {
    name: 'ruleStep2',
    mixins: [formPage],
    data() {
        return {
            checked: false,
            form: {
                chCompanyName: '',
                enterpriseType: [],
                branch: true,
                productCategoryId: [],
                applicationField: []
            },
            productCategorys: [],
            applications: [],
            noEnd: false,
            rules: {
                logo: {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (value && this.form.logo1) {
                            callback();
                        } else {
                            callback(' ');
                        }
                    },
                    trigger: 'change'
                },
                address: {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (this.branchInformation.country) {
                            callback();
                        } else {
                            callback(' ');
                        }
                    },
                    trigger: 'change'
                },
                businessLicensePeriod: {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (value || this.form.noLimit) {
                            callback();
                        } else {
                            callback(this.$t('qing-xuan-ze-qi-xian'));
                        }
                    },
                    trigger: 'change'
                },
                uscc: {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (value && /[A-Z0-9]{18}/g.test(value)) {
                            callback();
                        } else if (!value) {
                            callback(this.$t('qing-shu-ru-tong-yi-she-hui-xin-yong-dai-ma'));
                        } else {
                            callback(this.$t('xin-yong-dai-ma-ge-shi-cuo-wu'));
                        }
                    },
                    trigger: 'blur'
                },
                otherFiles: {
                    required: false,
                    validator: (rule, value, callback) => {
                        callback();
                    },
                    trigger: 'change'
                }
            },
            branchInformation: {}
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo', 'buyersInfo']),
        boolOptions() {
            return [...boolModels].map(item => {
                return {
                    label: item[1],
                    value: item[0]
                };
            });
        },
        enterpriseTypes() {
            return [...EnterpriseType].map(item => {
                return {
                    label: this.$i18n.t(item[1]),
                    value: item[0]
                };
            });
        },
        branchInformationJSON() {
            return JSON.stringify([this.branchInformation]);
        }
    },
    watch: {
        noEnd() {
            if (this.noEnd) {
                this.form.businessLicensePeriod = '';
            }
        },
        buyersInfo() {
            if (this.buyersInfo) {
                this.setValue(this.buyersInfo);
            }
        }
    },
    mounted() {
        // this.form.chCompanyName = this.userInfo.chCompanyName;
        // this.form.addressB = {
        //     city: this.userInfo.city,
        //     country: this.userInfo.country,
        //     province: this.userInfo.province
        // };
        this.$store.dispatch('getBuyInfo').then(res => {
            let data = { ...res };
            let businessLicensePeriod = '';
            if (this.buyersInfo) {
                data = { ...this.buyersInfo };
            }
            this.setValue(data);

            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });

            //全球总部地址
            if (res.branchInformation) {
                this.branchInformation =
                    JSON.parse(res.branchInformation).length > 0
                        ? JSON.parse(res.branchInformation)[0]
                        : {
                              nickname: '',
                              phone: '',
                              sex: '',
                              email: '',
                              position: '',
                              city: res.city || '',
                              province: res.province || '',
                              country: res.country || '',
                              continent: res.continent || '',
                              area: '',
                              address: res.address || '',
                              postalCode: ''
                          };
            } else {
                this.branchInformation = {
                    nickname: '',
                    phone: '',
                    sex: '',
                    email: '',
                    position: '',
                    city: res.city || '',
                    province: res.province || '',
                    country: res.country || '',
                    continent: res.continent || '',
                    area: '',
                    address: res.address || '',
                    postalCode: ''
                };
            }
        });

        // if (this.buyersInfo) {
        //     this.form = { ...this.buyersInfo };
        // } else {
        //     this.$http
        //         .post('/buyersInfo/getInfo', {
        //             userId: this.$store.state.userInfo.id
        //         })
        //         .then(res => {
        //             this.buyersInfo = res;
        //             if (res.businessLicensePeriod) {
        //                 res.businessLicensePeriod = parse(res.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss', new Date());
        //             }
        //             res.enterpriseType = res.enterpriseType || [];

        //             const form = {
        //                 branch: true,
        //                 businessLicenseImg: '',
        //                 businessLicensePeriod: '',
        //                 uscc: ''
        //             };

        //             for (var key in res) {
        //                 if (keys.includes(key)) {
        //                     form[key] = res[key] !== null ? res[key] : '';
        //                 }
        //             }

        //             this.form = form;

        //         });
        // }
    },
    methods: {
        setValue(data) {
            const keys = [
                'userId',
                'branch',
                'chCompanyName',
                'enCompanyName',
                'homepage',
                'enterpriseType',
                'productCategoryId',
                'applicationField',
                'customApplicationField',
                'logo',
                'logo1',
                'uscc',
                'businessLicensePeriod',
                'businessLicenseImg',
                'idimg',
                'otherFiles',
                'country',
                'province',
                'city',
                'area',
                'address',
                'introduction'
            ];

            const form = {
                branch: true,
                businessLicenseImg: '',
                businessLicensePeriod: '',
                uscc: '',
                enterpriseType: [],
                noLimit: false
                // otherFiles:[]
            };

            for (var _key in data) {
                if (keys.includes(_key) && !!data[_key]) {
                    form[_key] = data[_key];
                }
            }

            if (form.businessLicensePeriod) {
                form.businessLicensePeriod = parse(form.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss', new Date());
            }

            // form.otherFiles = form.otherFiles?.split(',') || [];

            if (this.buyersInfo && this.buyersInfo.vendorId) {
                form.vendorId = this.buyersInfo.vendorId;
            }
            this.form = form;
        },
        next() {
            this.$parent.next();
        },
        submit() {
            var formDtata = { ...this.form };

            if (formDtata.businessLicensePeriod) {
                formDtata.businessLicensePeriod = format(formDtata.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss');
            }

            if (formDtata.noLimit) {
                formDtata.businessLicensePeriod = '';
            }
            if (!formDtata.vendorId) {
                formDtata.userId = this.userInfo.id;
            }

            // formDtata.otherFiles = formDtata.otherFiles?.join(',') || '';

            formDtata.branchInformation = this.branchInformationJSON;
            this.loading = true;

            this.$http
                .post('/vendorInfo/become', formDtata, { body: 'json' })
                .then(() => {
                    this.$store.dispatch('getBuyInfo').then(() => {
                        this.$nextTick(() => {
                            if (this.buyersInfo) {
                                window.localStorage.setItem(
                                    `saveVendorInfo_${this.buyersInfo.vendorId}`,
                                    this.buyersInfo.status
                                );
                            }
                            this.$parent.next();
                            this.loading = false;
                        });
                    });
                })
                .catch(e => {
                    this.$message.warning(e.error);
                    this.loading = false;
                });
        }
    },
    components: {
        Cascader,
        ProductCategory,
        ApplicationField,
        Area
    }
};
</script>

<style lang="scss" scoped>
.el-form {
    padding-top: 40px;
    padding-bottom: 60px;
    min-height: 50vh;
    box-sizing: border-box;
}

.logoList {
    display: flex;
    align-items: flex-end;

    .tips {
        text-align: center;
    }
}
.logoList > div {
    &:first-child {
        margin-right: 16px;
    }
}

.form-right {
    .el-checkbox {
        padding: 0 20px;
    }
}
</style>

<style lang="scss">
.el-cascader-node.in-active-path,
.el-cascader-node.is-selectable.in-checked-path,
.el-cascader-node.is-active {
    color: #ffa526;
}
</style>
